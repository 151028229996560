import React from 'react'
import {
  NavBar,
  NavBarContainer,
  NavBarLink,
  NavbarLogo,
  NavbarIcon
} from 'atoms/Navbar'
import logo from 'images/tenderx_logo.svg'
import tenderXicon from 'images/tenderx_icon.svg'

const NavbarComponent = () => (
  <NavBar>
    <NavBarContainer>
      <div>
        <NavBarLink exact to='/#hero'>
          <NavbarLogo src={logo} />
          <NavbarIcon src={tenderXicon} />
        </NavBarLink>
      </div>
      <div>
        <NavBarLink to='/#product'> Overview </NavBarLink>
        <NavBarLink to='/#solutions'> Solutions </NavBarLink>
        <NavBarLink to='/#sample-reports'> Sample reports </NavBarLink>
        <NavBarLink to='/#about'> About us </NavBarLink>
        <NavBarLink to='/search'> Get started </NavBarLink>
      </div>
    </NavBarContainer>
  </NavBar>
)

export default NavbarComponent
