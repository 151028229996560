import styled from 'styled-components'
import { Container, Row, Col } from 'react-awesome-styled-grid'
import Foundations from 'configs/Foundations'
import device from 'configs/Device'

export const Grid = styled(Container)`
  width: 100%;
`

export const GridRow = styled(Row)`
  margin-bottom: 0px;
`

export const GridCol = styled(Col)`
  margin: calc(${Foundations.SpacingUnit} / 2) 0px;
  p {
    z-index: 1;
  }
  @media ${device.md} {
    margin: ${Foundations.SpacingUnit} 0px;
  }
`

export default Grid
