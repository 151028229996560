import styled from 'styled-components'
import Foundations from 'configs/Foundations'
import device from 'configs/Device'
import Container from 'atoms/Container'
import { NavLink } from 'atoms/Link'

export const NavBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: ${Foundations.NavbarHeight};
  color: ${Foundations.colors.light};
  background-color: ${Foundations.colors.primary};
  box-shadow: 2px 2px 4px ${Foundations.colors.primaryDark};
  font-size: 18px;
`

export const NavBarContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${device.sm} {
    display: flex;
  }
`

export const NavBarLink = styled(NavLink)`
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  :hover {
    opacity: 0.6;
  }
  :active {
    color: ${Foundations.colors.secondary};
    font-weight: bold;
  }
  :not(:last-child) {
    padding-right: calc(${Foundations.SpacingUnit} * 2);
  }
  @media ${device.sm} {
    font-size: 16px;
  }
`
export const NavbarLogo = styled.img`
  position: relative;
  display: none;
  height: calc(${Foundations.SpacingUnit} * 5);
  @media ${device.sm} {
    display: inline-block;
  }
`
export const NavbarIcon = styled.img`
  position: relative;
  display: inline-block;
  height: calc(${Foundations.SpacingUnit} * 4);
  @media ${device.sm} {
    display: none;
  }
`

export default NavBar
