const Foundations = {
  BaseFontSize: '16px',
  SpacingUnit: '8px',
  Roundness: '8px',
  NavbarHeight: '72px',
  FooterHeight: '180px',
  colors: {
    primary: '#0FA989',
    primaryPale: '#D8F8F1',
    primaryLight: '#A1E2D5',
    primaryDark: '#109479',
    secondary: '#FFD400',
    secondaryLight: '#FFE52B',
    secondaryDark: '#E9B300',
    terciary: '#F7941D',
    terciaryPale: '#FFF3E4',
    terciaryLight: '#FFAA44',
    terciaryDark: '#E27C00',
    dark: '#002838',
    grey: '#C8C8C8',
    greyLight: '#EDEDED',
    light: '#FFFFFF',
    lightWarm: '#FFFAE5',
    error: '#F7371D',
    info: '#0077A7',
    infoPale: '#D3F2FF'
  }
}

export default Foundations
