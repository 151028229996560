import React from 'react'
import { HashLink, NavHashLink } from 'react-router-hash-link'

const scrollWithOffset = el => {
  // copied from https://github.com/rafrex/react-router-hash-link/issues/25
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset
  const yOffset = -60
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' })
}

export const NavLink = props => (
  <NavHashLink smooth scroll={scrollWithOffset} {...props} />
)

export const Link = props => (
  <HashLink smooth scroll={scrollWithOffset} {...props} />
)

export default Link
