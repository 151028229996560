import styled from 'styled-components'
import Foundations from 'configs/Foundations'
import device from 'configs/Device'
import tenderXicon from 'images/tenderx_icon.svg'

export const Footer = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  width: 100%;
  height: ${Foundations.FooterHeight};
  padding: calc(${Foundations.SpacingUnit}*2) ${Foundations.SpacingUnit} 0px;
  color: ${Foundations.colors.lightWarm};
  background-color: ${Foundations.colors.primary};
  font-size: 14px;
  over-flow: hidden;
  @media ${device.md} {
    align-items: flex-start;
    padding: calc(${Foundations.SpacingUnit}*5) 0px;
  }
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background: url(${tenderXicon}) no-repeat center right;
    background-size: 600px 595px;
    mix-blend-mode: soft-light;
    opacity: 0.45;
  }
`

export const FooterNav = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  @media ${device.md} {
    flex-direction: column;
  }
`

export const FooterIcon = styled.img`
  width: calc(${Foundations.SpacingUnit} * 2);
  height: calc(${Foundations.SpacingUnit} * 2);
  vertical-align: text-bottom;
  mix-blend-mode: screen;
`

export default Footer
