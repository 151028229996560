import styled, { css } from 'styled-components'
import Foundations from 'configs/Foundations'
import device from 'configs/Device'
import { Link } from 'atoms/Link'

export const TextLink = styled(Link)`
  transition: all 0.2s ease-in-out;
  width: fit-content;
  padding-right: calc(${Foundations.SpacingUnit} * 2);
  color: ${Foundations.colors.primaryDark};
  transition: all 0.2s ease-in-out;
  @media ${device.md} {
    padding-right: none;
  }
  :hover {
    opacity: 0.6;
  }
  :active {
    color: ${Foundations.colors.primary};
  }
  ${props =>
    props.color === 'light' &&
    css`
      color: ${Foundations.colors.lightWarm};
      :hover {
        opacity: 0.6;
      }
      :active {
        color: ${Foundations.colors.primaryLight};
      }
    `}
  ${props =>
    props.kind === 'intext' &&
    css`
      padding: 0px;
      @media ${device.md} {
        padding: 0px;
      }
    `}
`

export default TextLink
