import styled from 'styled-components'
import Foundations from 'configs/Foundations'
import device from 'configs/Device'

const containerW = {
  xxs: '480px',
  xs: '600px',
  sm: '720px',
  md: '880px',
  lg: '1080px'
}

const Container = styled.div`
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 0px calc(${Foundations.SpacingUnit}*2);

  @media ${device.xxs} {
    max-width: ${containerW.xxs};
  }
  @media ${device.xs} {
    max-width: ${containerW.xs};
    padding: 0px;
  }
  @media ${device.sm} {
    max-width: ${containerW.sm};
  }
  @media ${device.md} {
    max-width: ${containerW.md};
  }
  @media ${device.lg} {
    max-width: ${containerW.lg};
  }
`

export default Container
