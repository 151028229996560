import styled from 'styled-components'
import Foundations from 'configs/Foundations'
import { AssignmentLate } from '@styled-icons/material'

export const EmptyState = styled.div`
  width: 100%;
  border-radius: 0px 0px 2px 2px;
  border: 2px solid ${Foundations.colors.terciary};
  border-top: none;
  padding: calc(${Foundations.SpacingUnit} * 4)
    calc(${Foundations.SpacingUnit}*2);
  color: ${Foundations.colors.terciary};
  background: ${Foundations.colors.terciaryPale};
  text-align: center;
`
export const AlertIcon = styled(AssignmentLate)`
  color: ${Foundations.colors.terciary};
  margin-bottom: ${Foundations.SpacingUnit};
`

export default EmptyState
