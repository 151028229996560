// ACTION TYPES

export const REQUEST_REPORT = 'REQUEST_REPORT'
export const REQUEST_REPORT_SUCCEEDED = 'REQUEST_REPORT_SUCCEEDED'
export const REQUEST_REPORT_FAILED = 'REQUEST_REPORT_FAILED'

// ACTION CREATORS

export const requestReport = payload => ({
  type: REQUEST_REPORT,
  payload
})
