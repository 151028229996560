// ACTION TYPES

export const SET_SEARCH_META = 'SET_SEARCH_META'
export const SELECT_COMPANY = 'SELECT_COMPANY'
export const SET_SEARCH_OPTIONS = 'SET_SEARCH_OPTIONS'
export const UNSELECT_COMPANY = 'UNSELECT_COMPANY'
export const UNSELECT_COMPANIES = 'UNSELECT_COMPANIES'
export const INCREMENT_PAGE = 'INCREMENT_PAGE'
export const DECREMENT_PAGE = 'DECREMENT_PAGE'
export const GO_TO_PAGE = 'GO_TO_PAGE'

// ACTION CREATORS

export const setSearchMeta = payload => ({
  type: SET_SEARCH_META,
  payload
})

export const selectCompany = payload => ({
  type: SELECT_COMPANY,
  payload
})

export const setSearchOptions = payload => ({
  type: SET_SEARCH_OPTIONS,
  payload
})

export const unselectCompany = payload => ({
  type: UNSELECT_COMPANY,
  payload
})

export const unselectCompanies = () => ({
  type: UNSELECT_COMPANIES
})

export const nextCompaniesPage = () => ({
  type: INCREMENT_PAGE
})

export const previousCompaniesPage = () => ({
  type: DECREMENT_PAGE
})

export const goToCompaniesPage = payload => ({
  type: GO_TO_PAGE,
  payload
})
