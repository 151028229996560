import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import { PRODUCTION } from '../src/configs/constants'
import app from '../src'

if (PRODUCTION) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_ENV
  })
}

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('root')
  ReactDOM.render(app, node)
})
