import React from 'react'
import { Strip as ColoredStrip, StripSection } from 'atoms/Strip'

const Strip = props => (
  <ColoredStrip {...props}>
    <StripSection />
    <StripSection />
    <StripSection />
    <StripSection />
    <StripSection />
  </ColoredStrip>
)

export default Strip
