import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { ApolloClient } from 'apollo-client'

export default new ApolloClient({
  link: new HttpLink({
    uri: `${window.location.origin}/graphql`
  }),
  cache: new InMemoryCache()
})
