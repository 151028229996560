import React from 'react'
import Container from 'atoms/Container'
import { GridRow, GridCol } from 'atoms/Grid'
import { Footer, FooterNav, FooterIcon } from 'atoms/Footer'
import { TextLink } from 'atoms/TextLink'
import { Copyright } from '@styled-icons/fa-regular'
import tenderXicon from 'images/tenderx_icon.svg'
import Strip from './Strip'

const FootComponent = () => (
  <Footer>
    <Strip kind='fixed' />
    <Container>
      <GridRow>
        <GridCol xs={12} md={6}>
          <p>
            <FooterIcon src={tenderXicon} /> TenderX Risk Consultancy Kft. is
            registered in Budapest, Hungary
          </p>
          <p>
            Contact us:{' '}
            <TextLink color='light' href='mailto:info@tenderx.eu'>
              info@tenderx.eu
            </TextLink>
          </p>
          <br />
          <p>
            <Copyright size='14' title='copyright' /> Tender-X Risk Consultancy
            Kft. 2020
          </p>
        </GridCol>
        <GridCol xs={12} md={6}>
          <FooterNav>
            <TextLink color='light' to='/policies#cookie'>
              Cookie policy
            </TextLink>
            <TextLink color='light' to='/policies#privacy'>
              Privacy policy
            </TextLink>
            <br />
            <TextLink color='light' to='/'>
              Sitemap
            </TextLink>
          </FooterNav>
        </GridCol>
      </GridRow>
    </Container>
  </Footer>
)

export default FootComponent
