import styled, { css } from 'styled-components'
import Foundations from 'configs/Foundations'

export const Strip = styled.div`
  display: flex;
  width: 100%;
  height: calc(${Foundations.SpacingUnit} * 2);
  ${props =>
    props.kind === 'fixed' &&
    css`
      position: absolute;
      top: 0px;
      left: 0px;
    `}
`

export const StripSection = styled.div`
  width: 20%;
  height: 100%;
  &:nth-child(1) {
    background: ${Foundations.colors.dark};
  }
  &:nth-child(2) {
    background: ${Foundations.colors.info};
  }
  &:nth-child(3) {
    background: ${Foundations.colors.primaryDark};
  }
  &:nth-child(4) {
    background: ${Foundations.colors.secondary};
  }
  &:nth-child(5) {
    background: ${Foundations.colors.terciary};
  }
`

export default Strip
