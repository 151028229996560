import { applyMiddleware, combineReducers, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction'
import rootSaga from './sagas'
import {
  selectedCompanies,
  searchOptions,
  searchMeta
} from './reducers/companies'
import { reportDetails, reportStatus } from './reducers/reports'

const sagaMiddleware = createSagaMiddleware()

const store = createStore(
  combineReducers({
    searchMeta,
    searchOptions,
    reportDetails,
    reportStatus,
    selectedCompanies
  }),
  composeWithDevTools(applyMiddleware(sagaMiddleware))
)

sagaMiddleware.run(rootSaga)

export default store
