import { call, put } from 'redux-saga/effects'
import {
  REQUEST_REPORT_SUCCEEDED,
  REQUEST_REPORT_FAILED
} from 'actions/reports'
import { mutateReport } from 'graphql/mutations'

function* requestReport(action) {
  try {
    yield call(mutateReport, action.payload)
    yield put({ type: REQUEST_REPORT_SUCCEEDED })
  } catch (statusCode) {
    yield put({
      type: REQUEST_REPORT_FAILED,
      payload: statusCode
    })
  }
}

export default requestReport
