import {
  SET_SEARCH_META,
  SELECT_COMPANY,
  SET_SEARCH_OPTIONS,
  UNSELECT_COMPANY,
  UNSELECT_COMPANIES,
  GO_TO_PAGE,
  INCREMENT_PAGE,
  DECREMENT_PAGE
} from 'actions/companies'
import {
  DEFAULT_SEARCH_OPTIONS,
  DEFAULT_LIMIT,
  DEFAULT_SEARCH_META
} from 'configs/constants'

export const selectedCompanies = (state = [], action) => {
  switch (action.type) {
    case SELECT_COMPANY:
      return state.concat(action.payload)
    case UNSELECT_COMPANY:
      return state.filter(each => each.id !== action.payload.id)
    case UNSELECT_COMPANIES:
      return []
    default:
      return state
  }
}

export const searchOptions = (state = DEFAULT_SEARCH_OPTIONS, action) => {
  switch (action.type) {
    case SET_SEARCH_OPTIONS:
      return { ...DEFAULT_SEARCH_OPTIONS, ...action.payload }
    case INCREMENT_PAGE:
      return { ...state, offset: state.offset + DEFAULT_LIMIT }
    case DECREMENT_PAGE:
      return { ...state, offset: state.offset - DEFAULT_LIMIT }
    case GO_TO_PAGE:
      return { ...state, offset: action.payload * DEFAULT_LIMIT }
    default:
      return state
  }
}

export const searchMeta = (state = DEFAULT_SEARCH_META, action) => {
  switch (action.type) {
    case SET_SEARCH_META:
      return action.payload
    default:
      return state
  }
}
