import {
  REQUEST_REPORT,
  REQUEST_REPORT_SUCCEEDED,
  REQUEST_REPORT_FAILED
} from 'actions/reports'

export const reportDetails = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_REPORT:
      return action.payload
    default:
      return state
  }
}

export const reportStatus = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_REPORT:
      return {}
    case REQUEST_REPORT_SUCCEEDED:
      return { success: true }
    case REQUEST_REPORT_FAILED:
      return { success: false, error: action.payload }
    default:
      return state
  }
}
