import { createGlobalStyle } from 'styled-components'
import Foundations from 'configs/Foundations'

const GlobalStyles = createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap')
html {
  box-sizing: border-box;
  height: 100%;
  font-size: 100%;
}

html,
body {
  width: 100%;
  height: 100%;
}

* {
  &,
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

body{
  margin: 0px;
  color:#505050;
  font: normal 1rem/1.5 'Lato', -apple-system, BlinkMacSystemFont,"Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,"Open Sans","Helvetica Neue", sans-serif;
}

#root{
  position: relative;
  min-height: 100%;
  padding-top:${Foundations.NavbarHeight};
  padding-bottom: ${Foundations.FooterHeight};
}

h1,h2,h3,h4,h5,h6,p{
  margin:0px;
}

br {
  margin-bottom: 17px;
}
`

export default GlobalStyles
