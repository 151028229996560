import breakpoints from 'configs/Breakpoints'

const Device = {
  xxs: `(min-width: ${breakpoints.xxs})`,
  xs: `(min-width: ${breakpoints.xs})`,
  sm: `(min-width: ${breakpoints.sm})`,
  md: `(min-width: ${breakpoints.md})`,
  lg: `(min-width: ${breakpoints.lg})`
}

export default Device
