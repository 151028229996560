import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import store from './store'
import { AwesomeGridTheme } from './configs/AwesomeGridTheme'
import GlobalStyles from './configs/GlobalStyles'
import Footer from './components/Footer'
import Loader from './components/Loader'
import Navbar from './components/Navbar'
import ErrorBoundary from './containers/ErrorBoundary'
import withTracker from './containers/WithTracker'

const Home = lazy(() => import('./pages/HomePage'))
const Policies = lazy(() => import('./pages/PoliciesPage'))
const Search = lazy(() => import('./pages/SearchPage'))
const Demo = lazy(() => import('./pages/DemoPage'))
const Proceed = lazy(() => import('./pages/ProceedPage'))
const Request = lazy(() => import('./pages/RequestPage'))
const Thanks = lazy(() => import('./pages/ThanksPage'))

export const routes = (
  <Switch>
    <Route path='/' exact component={withTracker(Home)} />
    <Route path='/policies' component={withTracker(Policies)} />
    <Route path='/search' component={withTracker(Search)} />
    <Route path='/demo' component={withTracker(Demo)} />
    <Route path='/proceed' component={withTracker(Proceed)} />
    <Route path='/request' component={withTracker(Request)} />
    <Route path='/thanks' component={withTracker(Thanks)} />
  </Switch>
)

export default (
  <BrowserRouter>
    <ThemeProvider theme={AwesomeGridTheme}>
      <GlobalStyles />
      <Provider store={store}>
        <Navbar />
        <ErrorBoundary>
          <Suspense fallback={<Loader />}>{routes}</Suspense>
        </ErrorBoundary>
        <Footer />
      </Provider>
    </ThemeProvider>
  </BrowserRouter>
)
