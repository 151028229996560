export const AwesomeGridTheme = {
  awesomegrid: {
    mediaQuery: 'only screen',
    columns: {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12
    },
    gutterWidth: {
      xs: 1.5,
      sm: 1.5,
      md: 2,
      lg: 2.5,
      xl: 2.5
    },
    paddingWidth: {
      xs: 0,
      sm: 0,
      md: 0,
      lg: 0,
      xl: 0
    },
    container: {
      xs: 'full',
      sm: 'full',
      md: 'full',
      lg: 'full',
      xl: 'full'
    },
    breakpoints: {
      xs: 1,
      sm: 48, // 768px
      md: 62, // 992px
      lg: 75, // 1200px
      xl: 90 // 1440px
    }
  }
}

export default AwesomeGridTheme
