import React from 'react'
import { Provider } from 'react-redux'
import { ApolloProvider } from '@apollo/react-hooks'

import router from './router'
import client from './graphql/client'
import store from './store'

export default (
  <ApolloProvider client={client}>
    <Provider store={store}>{router}</Provider>
  </ApolloProvider>
)
