import gql from 'graphql-tag'
import client from './client'

export const REQUEST_REPORT_MUTATION = gql`
  mutation RequestReportMutation(
    $companyIds: [ID!]!
    $email: String!
    $firstName: String!
    $lastName: String!
    $organization: String!
  ) {
    requestReport(
      companyIds: $companyIds
      email: $email
      firstName: $firstName
      lastName: $lastName
      organization: $organization
    ) {
      response
    }
  }
`

export const mutateReport = variables =>
  client.mutate({ mutation: REQUEST_REPORT_MUTATION, variables })

// HOW TO
//
// ({ companyIds, email }) => {
//   const onClick = () => mutateReport({ companyIds, email })
//   return <button onClick={requestReport}> Request </button>
// }
