export const PRODUCTION = process.env.NODE_ENV === 'production'
export const DEFAULT_LIMIT = PRODUCTION ? 50 : 10
export const DEFAULT_COUNTRY = 'UK'
export const DEFAULT_COMPANY_TYPE = '0'
export const DEFAULT_OFFSET = 0

export const DEFAULT_SEARCH_OPTIONS = {
  companyType: DEFAULT_COMPANY_TYPE,
  country: DEFAULT_COUNTRY,
  limit: DEFAULT_LIMIT,
  offset: DEFAULT_OFFSET
}

export const DEFAULT_SEARCH_META = {
  totalCount: 0,
  totalPages: 0,
  currentPage: 0,
  nextOffset: null,
  previousOffset: null
}

export const DEFAULT_COUNTRY_CODES = [
  { code: 'BE', name: 'Belgium' },
  { code: 'BG', name: 'Bulgaria' },
  { code: 'CL', name: 'Chile' },
  { code: 'CO', name: 'Colombia' },
  { code: 'HR', name: 'Croatia' },
  { code: 'CY', name: 'Cyprus' },
  { code: 'CZ', name: 'Czech Republic' },
  { code: 'DK', name: 'Denmark' },
  { code: 'EC', name: 'Ecuador' },
  { code: 'EE', name: 'Estonia' },
  { code: 'FI', name: 'Finland' },
  { code: 'FR', name: 'France' },
  { code: 'GE', name: 'Georgia' },
  { code: 'DE', name: 'Germany' },
  { code: 'GR', name: 'Greece' },
  { code: 'HU', name: 'Hungary' },
  { code: 'ID', name: 'Indonesia' },
  { code: 'IE', name: 'Ireland' },
  { code: 'IT', name: 'Italy' },
  { code: 'LV', name: 'Latvia' },
  { code: 'LT', name: 'Lithuania' },
  { code: 'LU', name: 'Luxembourg' },
  { code: 'MT', name: 'Malta' },
  { code: 'MX', name: 'Mexico' },
  { code: 'NL', name: 'Netherlands' },
  { code: 'NO', name: 'Norway' },
  { code: 'PY', name: 'Paraguay' },
  { code: 'PL', name: 'Poland' },
  { code: 'PT', name: 'Portugal' },
  { code: 'RO', name: 'Romania' },
  { code: 'SK', name: 'Slovakia' },
  { code: 'SI', name: 'Slovenia' },
  { code: 'ES', name: 'Spain' },
  { code: 'SE', name: 'Sweden' },
  { code: 'CH', name: 'Switzerland' },
  { code: 'UK', name: 'United Kingdom' },
  { code: 'US', name: 'United States' },
  { code: 'UY', name: 'Urugua' }
]
